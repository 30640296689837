
.toast-success,
.toast-warning,
.toast-info,
.toast-error {
  background: white;
}


#toast-container {

  .toast-error {

    .toast-progress {
      background-color: #bd362f;
    }

    .toast-message {
      color: #bd362f;
    }



    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAJZQTFRFAAAA3ZeU03l0yFhS14aC3pqW2YuH0ndzwUI7vTYvwkU+z25p25KP0HBrz21ozWZgyl5ZxU9JyVxWzGRfxExGwD84vjky1oSA3JSQy2Jdy2BbyVpUvzw12YyI0HJt03p1zWhi2IqFw0dB14WB1H141YB82IiEx1NN3ZaT25KO1oJ93ZmV1oN/1Hx30XNu3ZiUw0pD1H55bPVTcgAAADJ0Uk5TAB/A9IwIdsP+//3YQ9Xb5u/68ej7/v+XPOvt8/9w0bzjfP2Ss6aB9yZKoRCcuM4X/K9LQZIcAAAA3ElEQVR4nG1SWwKCMAwriAioUxEEFV/4QlHQ+1/ObrRzCv1KkzGadABclt1z+vBfljvwfN8Phs5Ic2Mxmc78b3nhRMynKESaig0ZhZDgIoF0aQorgmvELvMZNoKwvHbDQoTNtkuQn++MQ1rYY3MgnCM+snCS3oxBzowv0uGVGgvAZqFQ1qm5AQwJhioTvjgBYIN3JZQ5HXs8OYSiiZEH1rWnfIvsl89STr5qrJ+rJt+BXkkto89wXPWTV/1doIrChVJOld/M1b5VWnGLJ6WDR5vyMXi21XonkIrApD8l6BPrP8eXPAAAAABJRU5ErkJggg==) !important;
  }

  .toast-warning {
    .toast-progress {
      background-color: #f89406;
    }

    .toast-message {
      color: #f89406;
    }

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAJZQTFRFAAAA+8N0+8Br+ag1+aEl+8mB+r5o+JQG+rlc+Z4f+acz+a5D+JkR+8d8+rhZ+JYK+8R2+JoV+rFK+8Z6+JcO+rdX+rxk+8Jz+q9G+aIo+ak4+8Jy+8Jx+8V4+r1n+8mC+aUt+rJM+8d9+rha+Z0b+8iA+8h++aQq+JwY+rNO+aAi+rBI+as9+8R2+8Z7+rld+8V5+sBsAsFMaQAAADJ0Uk5TAF2H8foQkv+4/PPj/i7A/1D+2zz/w6Fk4fnvanBKlwj22Ca8/Rcf9/3V+97rVzWzQ4GgFb2CAAAAyklEQVR4nKVQ2RKCMAyMcgSwiNyKRwURBAH1/39OSkFBcMYZ87Dp7KZJNgD/xGz+RRBEaZKXEZUpXlK1BdEnhKWx0k1rzNuOC+ChPxLWGzY50D75Le5Y2juHIU+PIVDLiuAUnweCFycAiDbQ1O3zyUWukWAE4Dt2T8gEhjkyvBZv3jdKlkSDYUXk1+Q05Bs3/+r5SSsoJr+EzrtLedZ5vvENCN6bh9z6L0TKK1VS8dIgpY3+aFtKnbfSqO+fxOZyFHltJsPJ6Jn5NZ6bCgz0+FuPtwAAAABJRU5ErkJggg==) !important;

  }

  .toast-info {
    .toast-progress {
      background-color: #2f96b4;
    }

    .toast-message {
      color: #2f96b4;
    }

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAJlQTFRFAAAAh8LUabPJUKbARqK8VqrCc7jMjMXWk8jYYK/GL5a0aLLIlMnYhMHTQZ+6Q6C7hcHTOJu3gsDSlMnZi8TVPp65brbLYrDGj8bWMpi1O5y4iMPUdbnNW6zEjcXWSaO9S6S+X67Ffb3QcLfLkMfXfL3Qd7rONZm2ZrLIWavDUqjBjsbWisTUkMfXebvPgL/RbbXKXa3Ef77RXgxl/AAAADN0Uk5TAHbY9vvxw1cm5v/bH4H9/Hz+jBdd/c7jQ//+cLztUPr56KHKPKa4/97v9EpkNa+X0euc+5Vc4QAAAMRJREFUeJx1kekSgiAUhVHJDLJyzbWsLKXFlvd/uCbWkDo/4HK+uYfhAgCXZTsQTtwp0OXNEBeef/v+AkktfeWvmBVwHAo/gsyIY7YnHgcp0gFaMz/L+bmA4p6SggoZsimoRUC5WfJyS4EMcFWpAzwCjQkSCnYmcCjYm+DABlhIwFPzlr3wyINDcOpo1YthEXo8E4JpC5STv4gw1nlVc7/Vym8y7QuHToAe6IruKX4MVYDQ+NuZ2iciPwEAr8L6Q7zqs74BkRoSsa0xg80AAAAASUVORK5CYII=) !important;

  }

  .toast-success {
    .toast-progress {
      background-color: #51a351;
    }

    .toast-message {
      color: #51a351;
    }

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAGBQTFRFAAAAp9Cnj8SParBqgr2Cos6ip9GnfLp8UaNRWadZf7t/YKtgoc2hoc2hpdCli8KLcLNwjMKMptCmhr+Gos6insuemMiYcrRyVKRUbLFsfbp9iMCIkcWRmcmZn8yferl6AABjogAAACB0Uk5TABCv99g8COP//t79Q0ofwPO8F841XYfx//bhx6Z8V+YPzlKmAAAAbElEQVR4nLXQSQ6AMAgFULQO4Dxb63T/W2q6cNHCxuhfwUsDpAC/JghVFDOepIiYCo4kOGaC54Xg5Ruv6qbtuPf9XaqBmaNsM/rza9vS5O3tZgt68e4JDD5x7lwFB9g07wA78Q5wnGTcf/ssF2bCBzEtTxqFAAAAAElFTkSuQmCC) !important;

  }
}
.tiptap.ProseMirror {
  outline: none;
}